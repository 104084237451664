import React, { useEffect, useState, useContext, useReducer } from "react";
import { AuthContext } from "../context/authContext";
import * as GEReducer from "../reducers/GradeEntryReducer";
import * as GEService from "../services/gradeEntryService";
import { OverlayTrigger, Popover } from "react-bootstrap";
import swal from "sweetalert";

const useGradeEntry = ({ props, termcode, courseid }) => {
  const { dispatch, inRole } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [submitSchools, setsubmitSchools] = useState([]);
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [creditIdx, setCreditIdx] = useState(null);
  const [creditStudent, setCreditStudent] = useState(null);

  const [course, cdispatch] = useReducer(
    GEReducer.GradeChangeReducer,
    GEReducer.initialState
  );

  useEffect(() => {
    GEService.getData(
      courseid,
      termcode,
      course.Filters.schoolFilter,
      course.Filters.studentStatusFilter,
      course.Filters.gradingStatusFilter
    )
      .then(res => {
        cdispatch({ type: "Load", payload: res.data.GradeEntry });
        if (res.data.GradeEntry.Schools.length === 1) {
          setsubmitSchools([res.data.GradeEntry.Schools[0].collCode]);
        }
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  }, [
    courseid,
    termcode,
    course.Filters.schoolFilter,
    course.Filters.studentStatusFilter,
    course.Filters.gradingStatusFilter,
    props.history
  ]);

  const showGrade = (rec, index) => {
    if (rec.submittedGrdeCode) {
      if (rec.finalGrdeCode && rec.finalGrdeCode !== rec.submittedGrdeCode) {
        return (
          <span>{rec.finalGrdeCode + " [" + rec.submittedGrdeCode + "]"}</span>
        );
      } else {
        return <span>{rec.submittedGrdeCode}</span>;
      }
    } else if (rec.pendingStatus === "P") {
      return <span>{rec.savedGrdeCode}</span>;
    } else if (
      course.SecurityInfo.userRole === "REGS" &&
      !inRole("ROLE_DPRX")
    ) {
      return <span>{rec.savedGrdeCode}</span>;
    } else {
      if (course.SecurityInfo.userRole === "SINS") {
        return <span>{rec.submittedGrdeCode}</span>;
      } else if (rec.gradeCodes === null || rec.gradeCodes === undefined) {
        return (
          <OverlayTrigger
            overlay={
              <Popover id={"no-grade-tooltip"}>
                <Popover.Title>Grade Error</Popover.Title>
                <Popover.Content>
                  There is a problem retrieving the grades associated with this
                  student’s registered grade mode. Please contact the registrar
                  to resolve.
                </Popover.Content>
              </Popover>
            }
            placement={"right"}
            trigger={"hover"}
          >
            <i
              className={"fa fa-exclamation-circle fa-2x"}
              aria-hidden={"true"}
            ></i>
          </OverlayTrigger>
        );
      } else {
        if (course.SecurityInfo.gradingOpen === "Y") {
          return (
            <div>
              <label htmlFor={"grade_" + index} className={"d-none"}>
                Select Grade
              </label>
              <select
                title={"Select Grade"}
                id={"grade_" + index}
                className="form-control-md"
                style={{ minWidth: "100px" }}
                value={rec.savedGrdeCode == null ? "" : rec.savedGrdeCode}
                onChange={e => {
                  saveGrade({
                    index: index,
                    record: { ...rec, savedGrdeCode: e.target.value }
                  });
                }}
                disabled={false}
              >
                {rec.gradeCodes.split(",").map((grade, idx) => {
                  return (
                    <option key={idx} value={grade}>
                      {grade}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        } else {
          return <div></div>;
        }
      }
    }
  };

  const showOptionalGrade = (rec, index) => {
    if (rec.attrCodeOptionalFormat) {
      if (rec.submittedGrdeCode || course.SecurityInfo.userRole === "SINS") {
        return (
          <td>
            <div>
              {rec.attrCodeOptionalFormat + " "}
              {rec.submittedCompletedInd ? rec.submittedCompletedInd : ""}
            </div>
          </td>
        );
      } else {
        return (
          <td style={{ minWidth: "100px" }}>
            <div>
              {rec.attrCodeOptionalFormat + " "}
              <label htmlFor={"owc_" + index} className={"d-none"}>
                Select Optional Writing Credit
              </label>
              <select
                id={"owc_" + index}
                title={"Select Optional Writing Credit"}
                className="form-control-md"
                value={
                  rec.savedCompletedInd == null ? "" : rec.savedCompletedInd
                }
                onChange={e => {
                  saveGrade({
                    index: index,
                    record: { ...rec, savedCompletedInd: e.target.value }
                  });
                }}
                disabled={false}
              >
                {["", "Y", "N"].map((val, idx) => {
                  return (
                    <option key={idx} value={val}>
                      {val}
                    </option>
                  );
                })}
              </select>
            </div>
          </td>
        );
      }
    } else {
      return <td></td>;
    }
  };

  const saveGrade = val => {
    let dt = new Date();
    GEService.saveGrade({
      termCode: termcode,
      courseId: courseid,
      crn: val.record.crn,
      studentPidm: val.record.studentPidm,
      gradeCode: val.record.savedGrdeCode,
      userPidm: null,
      oldGradeCode: null,
      completedInd: val.record.savedCompletedInd
    })
      .then(res => {
        cdispatch({
          type: "Grade_Change",
          payload: {
            ...val,
            Totals: res.data.GradeEntry.Totals,
            record: {
              ...val.record,
              savedGrdeDate:
                val.record.savedGrdeCode === "" ? null : dt.toISOString()
            }
          }
        });
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  };

  const submitGrades = () => {
    setLoading(true);
    let vali = validateGradeSubmission();
    if (!vali) {
      if (submitSchools.length > 1) {
        setsubmitSchools(submitSchools.filter(i => i !== "YC"));
      } else {
        dispatch({
          type: "ERROR",
          payload:
            "Yale College Grades were not submitted; Yale College students with required optional writing credit must have a grade and an optional credit value."
        });
        setLoading(false);
        handleCloseSubmit();
        return null;
      }
    }
    GEService.submitGrades(courseid, termcode, submitSchools)
      .then(res => {
        cdispatch({
          type: "Submit_Grades",
          payload: res.data.GradeEntry
        });
        if (!vali) {
          dispatch({
            type: "ERROR",
            payload:
              "Yale College Grades were not submitted; Yale College students with required optional writing credit must have a grade and an optional credit value."
          });
        }
        setsubmitSchools([]);
        setLoading(false);
        handleCloseSubmit();
        if (submitSchools.includes("YC")) {
          props.history.push(`/eotreport/${termcode}/${courseid}`);
        }
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
        dispatch({
          type: "ERROR",
          payload:
            "Unable to submit grades to the Registrar.  Please try again later or contact the Registrar's office."
        });
        setLoading(false);
        handleCloseSubmit();
      });
  };

  const returnGrades = () => {
    setLoading(true);
    GEService.returnGrades(courseid, termcode)
      .then(res => {
        cdispatch({
          type: "Submit_Grades",
          payload: res.data.GradeEntry
        });
        setLoading(false);
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  };

  const validateGradeSubmission = () => {
    // make sure all grade entries are valid before submitting grades
    let retval = true;
    for (let s of submitSchools) {
      if (s === "YC") {
        // check if all YC Optional Dist Credits have been entered for students that have a saved grade
        let ocs1 = course.GradeList.filter(i => {
          return (
            i.attrCodeOptionalFormat !== null &&
            (i.savedCompletedInd === null || i.savedCompletedInd === "") &&
            i.savedGrdeCode !== null &&
            i.savedGrdeCode !== ""
          );
        });
        if (ocs1.length > 0) {
          retval = false;
          /*
          dispatch({
            type: "ERROR",
            payload:
              "Cannot submit grades; Some Yale College students have saved grades but also require the optional writing credit value."
          });
          */
        }
        let ocs2 = course.GradeList.filter(i => {
          return (
            i.attrCodeOptionalFormat !== null &&
            i.savedCompletedInd !== null &&
            i.savedCompletedInd !== "" &&
            (i.savedGrdeCode === null || i.savedGrdeCode === "")
          );
        });
        if (ocs2.length > 0) {
          retval = false;
          /*
          dispatch({
            type: "ERROR",
            payload:
              "Cannot submit grades; you must choose a grade for students with optional writing distribution credits set."
          });
          */
        }
      }
    }
    return retval;
  };

  const updateSubmitSchools = cb => {
    if (cb.target.checked) {
      setsubmitSchools([...submitSchools, cb.target.value]);
    } else {
      setsubmitSchools(submitSchools.filter(i => i !== cb.target.value));
    }
  };

  const canSubmitGrades = () => {
    let retval = false;
    let unsubmitted = course.GradeList.filter(i => {
      return i.submittedGrdeCode == null;
    });
    if (
      unsubmitted.length > 0 &&
      course.SecurityInfo.gradingOpen === "Y" &&
      course.SecurityInfo.userRole !== "SINS"
    ) {
      retval = true;
    }
    return retval;
  };

  const canApproveGrades = () => {
    let retval = false;
    if (course.SecurityInfo.userRole === "REGS" || inRole("ROLE_REGS")) {
      let pending = course.GradeList.filter(i => {
        return i.pendingStatus === "P";
      });
      if (pending.length > 0 && course.SecurityInfo.gradingOpen === "Y")
        retval = true;
    }
    return retval;
  };

  const canReturnGrades = () => {
    let retval = false;
    if (course.SecurityInfo.userRole === "REGS" || inRole("ROLE_REGS")) {
      let pending = course.GradeList.filter(i => {
        return i.pendingStatus === "P";
      });
      if (pending.length > 0 && course.SecurityInfo.gradingOpen === "Y")
        retval = true;
    }
    return retval;
  };

  const gradeModeChange = e => {
    cdispatch({
      type: "GradeMode_Change",
      payload: e.target.value
    });
  };

  const handleCloseSubmit = () => {
    if (submitSchools.length > 1) {
      setsubmitSchools([]);
    }
    setShowSubmit(false);
  };

  const handleShowSubmit = () => {
    setShowSubmit(true);
    //submitGrades();
  };

  const openVarCredit = idx => {
    setCreditsLoading(true);
    setCreditIdx(idx);

    setCreditStudent(course.GradeList[idx]);

    setCreditsLoading(false);
    setShowCredits(true);
  };

  const closeVarCredit = () => {
    setShowCredits(false);
    setCreditStudent(null);
    setCreditIdx(null);
  };

  const saveVarCredit = () => {
    GEService.saveVarCredit(
      termcode,
      creditStudent.studentPidm,
      creditStudent.crn,
      creditStudent.additionalCreditCode,
      creditStudent.varCredits,
      creditStudent.savedCommentText,
      creditStudent.processed
    )
      .then(res => {
        swal("Credits saved successfully!");
        cdispatch({
          type: "Credit_Update",
          payload: {
            index: creditIdx,
            record: {
              ...creditStudent,
              creditSavedDate: 1,
              savedCommentText: creditStudent.savedCommentText,
              additionalCreditCode: creditStudent.additionalCreditCode,
              varCredits: creditStudent.varCredits
            }
          }
        });
        setShowCredits(false);
      })
      .then(() => {
      });
  };

  const showCreditButton = (course, rec, index) => {
    if (course.CourseInfo.collList.includes("LW")) {
      if (rec.nonDispRegCollCode === "LW") {
        if (
          rec.savedCommentText !== null ||
          rec.additionalCreditCode !== null ||
          rec.varCredits !== null ||
          rec.creditSavedDate
        ) {
          return (
            <button
              aria-label={"Edit Law Credits and Comments"}
              className={"btn btn-sm btn-default"}
              onClick={() => {
                openVarCredit(index);
              }}
            >
              <i className={"fa fa-pencil-square-o"}></i>
            </button>
          );
        } else {
          if (
            course.SecurityInfo.gradingOpen &&
            !rec.submittedGrdeCode &&
            rec.pendingStatus !== "P" &&
            rec.pendingStatus !== "A"
          ) {
            if (course.SecurityInfo.userRole === "PINS") {
              return (
                <button
                  aria-label={"Add Law Credits and Comments"}
                  className={"btn btn-sm btn-default"}
                  onClick={() => {
                    openVarCredit(index);
                  }}
                >
                  <i className={"fa fa-plus-square-o"}></i>
                </button>
              );
            }
          } else {
            return <></>;
          }
        }
      }
    }
  };

  const canSubmitCredits = () => {
    let student = course.GradeList[creditIdx];
    if (!showCredits) {
      return false;
    } else if (!course.SecurityInfo.gradingOpen) {
      return false;
    } else if (course.SecurityInfo.userRole === "REGS" || inRole("ROLE_REGS")) {
      return !(student && student.finalGrdeCode);
    } else if (course.SecurityInfo.userRole === "PINS") {
      return (
        !student.submittedGrdeCode &&
        student.pendingStatus !== "P" &&
        student.pendingStatus !== "A"
      );
    } else return false;
  };

  const showLawAttribute = rec => {
    let creditCode = null;

    if (rec.additionalCreditCode) {
      creditCode = rec.additionalCreditCode;
    } else {
      creditCode = rec.studentAttribute;
    }

    if (creditCode) {
      if (creditCode === "LSAW") return "SAW";
      else if (creditCode === "LSUB") return "SP";
      else return creditCode;
    } else return "";
  };

  return [
    course,
    submitSchools,
    loading,
    showSubmit,
    showGrade,
    showOptionalGrade,
    canSubmitGrades,
    submitGrades,
    returnGrades,
    handleShowSubmit,
    handleCloseSubmit,
    updateSubmitSchools,
    gradeModeChange,
    canApproveGrades,
    canReturnGrades,
    closeVarCredit,
    saveVarCredit,
    showCreditButton,
    canSubmitCredits,
    showCredits,
    creditsLoading,
    setCreditStudent,
    creditStudent,
    showLawAttribute
  ];
};

export default useGradeEntry;
