import React, { useContext} from "react";
import { Button } from "react-bootstrap";
import * as config from "../config";
import { AuthContext } from "../context";
import parse from "html-react-parser";

const Home = props => {
  const { state } = useContext(AuthContext);

  return (
    <div>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-12 text-center"}>
            <h1 className={"py-5"}>Faculty Class Rosters - Grade Submission</h1>
            <br />
            <div style={{ textAlign: "center" }}>
              {!navigator.cookieEnabled ? (
                <div className={"alert alert-danger"}>
                  <h3 className={"alert-heading"}>Cookies not enabled</h3>
                  <p>Cookies must be enabled for this site to continue.</p>
                </div>
              ) : (
                <>
                  {state.error && (
                    <div className={"alert alert-danger"}>
                      {parse(state.error)}
                    </div>
                  )}
                  <div>
                    <p style={{ textAlign: "center" }}>
                      Please click on the button below to start this
                      application.
                    </p>
                    <Button
                      onClick={() =>
                        (window.location.href = `${config.API_URL}/oauth/authenticate/cas`)
                      }
                    >
                      Log into Grade Submission
                    </Button>
                  </div>
                </>
              )}
            </div>
            <p className={"mt-4"} style={{ textAlign: "center" }}>
              Unauthorized access will be prosecuted.
              <br />
              Use of Yale’s electronic systems is governed by applicable laws
              and policies.
              <br />
            </p>
           </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
