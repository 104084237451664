import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import queryString from "query-string";
import { jwtDecode } from "jwt-decode";
import * as api from "../services/api";

const AuthSuccess = props => {
  const { state, dispatch, inRole } = useContext(AuthContext);
  const params = queryString.parse(window.location.search);
  const [paramsError, setParamsError] = useState(params.error);
  const [paramsToken, setParamsToken] = useState(params.token);
  let redirect = JSON.parse(sessionStorage.getItem("redirect"));
  const [tkn] = useState({
    token: null,
    user: null,
    BannerUser: { showWelcome: null },
    selected_term: null
  });

  useEffect(() => {
    if (!paramsError) {
      if (paramsToken) {
        sessionStorage.setItem(
          "token",
          JSON.stringify({
            ...tkn,
            token: paramsToken,
            user: jwtDecode(paramsToken)
          })
        );
      }
      api
        .post(
          `/oauth/access_token?grant_type=refresh_token&refresh_token=${paramsToken}`
        )
        .then(
          res1 => {
            api
              .get("/api/user/details")
              .then(
                res2 => {
                  let u = jwtDecode(res1.data.access_token);
                  let val = {
                    token: res1.data.access_token,
                    exp: u.exp,
                    user: u,
                    BannerUser: res2.data.BannerUser,
                    selected_term: getDefaultTerm(res2.data.BannerUser.terms)
                  };
                  sessionStorage.setItem("token", JSON.stringify(val));
                  dispatch({
                    type: "LOGIN",
                    payload: val
                  });
                },
                error => {
                  //  Clear context and session variables
                  setParamsError(error.response.status.toString());
                  setParamsToken(null);
                  let errorMsg = "";
                  if (error.response.status === 403)
                    errorMsg =
                      "You are not authorized to participate in web grading for this " +
                      "term. If you thought that you would be participating, please contact " +
                      "us at " +
                      '<a href="mailto:fgs.support@yale.edu">fgs.support@yale.edu</a>';
                  else
                    errorMsg = `Login failed, please try again. (${error.response.status} - user details)`;
                  dispatch({
                    type: "LOGIN_ERROR",
                    payload: errorMsg
                  });
                  props.history.push("/");
                }
              )
              .finally(function() {
                sessionStorage.removeItem("redirect");
              });
          },
          error => {
            //  Handle any errors
            setParamsError(error.response.status.toString());
            setParamsToken(null);
            dispatch({
              type: "LOGIN_ERROR",
              payload: `Login failed, please try again. (${error.response.status} - token validation)`
            });
            props.history.push("/");
          }
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDefaultTerm = terms => {
    let dtrm = terms.filter(t => {
      return t.defaultTerm === "Y";
    });
    if (dtrm.length > 0) {
      return dtrm[0].termCode;
    } else {
      if (terms.length > 0) {
        return terms[0].termCode;
      } else {
        return null;
      }
    }
  };

  const showWelcome = () => {
    if (paramsError) {
      if (paramsError === "403") {
        return (
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <h1>Authorization Error</h1>
                <br />
                <p style={{ textAlign: "center" }}>
                  You are not authorized to participate in web grading for this
                  term.
                  <br />
                  If you thought that you would be participating, please contact
                  us at{" "}
                  <a href="mailto:fgs.support@yale.edu">fgs.support@yale.edu</a>
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <h1>Authorization Failed</h1>
            <pre>ERROR: {params.message}</pre>
          </div>
        );
      }
    }

    if (state.BannerUser.showWelcome !== null) {
      if (
        state.BannerUser.terms.length > 0 ||
        inRole("ROLE_RCD") ||
        inRole("ROLE_RCAA")
      ) {
        if (redirect) {
          return <Redirect to={redirect} />;
        } else {
          if (state.BannerUser.showWelcome === true) {
            return <Redirect to="/welcome" />;
          } else {
            if (inRole("ROLE_RCD") || inRole("ROLE_RCAA")) {
              return <Redirect to={`/rcdeotise`} />;
            } else {
              return <Redirect to="/courses" />;
            }
          }
        }
      } else {
        sessionStorage.removeItem("token");
        return (
          <div className={"container"}>
            <div className="row">
              <div className="col">
                <p>
                  You are not authorized to participate in web grading for this
                  term. If you thought that you would be participating, please
                  contact us at{" "}
                  <a href="mailto:fgs.support@yale.edu">fgs.support@yale.edu</a>
                </p>
              </div>
            </div>
          </div>
        );
      }
    }
    if (paramsToken) {
      return (
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-12"}>
              <h3>Signing in...</h3>
            </div>
          </div>
        </div>
      );
    }
  };

  return showWelcome();
};

export default AuthSuccess;
