import * as api from "../services/api";

export const getRoster = (
  courseid,
  termcode,
  schoolFilter,
  studentStatusFilter,
  gradingStatusFilter
) => {
  return api.post("/api/print/classRoster", {
    courseId: courseid,
    termCode: termcode,
    schoolFilter: schoolFilter,
    studentStatusFilter: studentStatusFilter,
    gradingStatusFilter: gradingStatusFilter
  });
};

export const getDeptReport = (
    deptSelection,
    termSelection,
    schoolSelection,
    gradeSelection,
    gradSelection) => {
  return api.post("/api/print/deptReport", {
    deptFilter: deptSelection,
    termCode: termSelection,
    schoolFilter: schoolSelection,
    gradeFilter: gradeSelection,
    gradFilter: gradSelection
  })
}

export const getGradeList = (
  courseid,
  termcode,
  anonymous,
  schoolFilter,
  studentStatusFilter,
  gradingStatusFilter
) => {
  return api.post("/api/print/gradeList.json", {
    courseId: courseid,
    termCode: termcode,
    anonymous: anonymous,
    schoolFilter: schoolFilter,
    studentStatusFilter: studentStatusFilter,
    gradingStatusFilter: gradingStatusFilter
  });
};

export const getSchools = () => {
  return api.get("/api/print/schoolsOptions");
}
