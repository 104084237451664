import React, { useCallback, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import * as FileReducer from "../reducers/FileUploadReducer";
import * as UploadService from "../services/uploadService";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { Col, Row, Button } from "react-bootstrap";
import { BreadcrumbTitle, PageHeader } from "../layout";
import ShortCourseTitle from "../components/shortCourseTitle";

const UploadGrades = props => {
  let { termcode, courseid } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [file, dispatch] = React.useReducer(
    FileReducer.FileUploadReducer,
    FileReducer.initialState
  );

  useEffect(() => {
    UploadService.getCourseInfo(courseid, termcode)
      .then(res => {
        dispatch({ type: "loadCourse", payload: res.data });
      })
      .catch(function(error) {
        if (error.response.status === 401) props.history.push("/sessionended");
        console.log(error);
      });
  }, [courseid, termcode, props.history]);

  const FileDropzone = () => {
    const onDrop = useCallback(acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          setLoadComplete(false);
          setLoading(false);

          var data = new Uint8Array(reader.result);
          var workbook = XLSX.read(data, { type: "array" });
          dispatch({
            type: "loadWorkbook",
            payload: { workbook: workbook, filename: file.path }
          });
        };
        reader.readAsArrayBuffer(file);
      });
    }, []);

    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps
    } = useDropzone({
      onDrop,
      multiple: false,
      accept: {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      },
      maxFiles: 1
    });


    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
          {file.path} - {file.size} bytes
          <ul>
            {errors.map(e => (
                <li key={e.code}>{e.message}</li>
            ))}
          </ul>
        </li>
    ));


    return (
      <section className="container">
        <div {...getRootProps({ className: "dropzone" })}>
          <input aria-label={"Select file upload"} {...getInputProps()} />
          <p>Drag spreadsheet here, or click to select file</p>
        </div>
        <div>
          <p>
            {fileRejections.length > 0 && (
              <>
                The following are not valid files:
                <ul>
                  {fileRejectionItems}
                </ul>
              </>
            )}
          </p>
        </div>
      </section>
    );
  };

  const PreviewGrades = () => {
    return (
      <div>
        <table>
          <thead>
            <tr>
              {file.columns.grade !== undefined &&
                file.columns.grade.length > 0 && <th>{file.columns.grade}</th>}
              {file.columns.optional !== undefined &&
                file.columns.optional.length > 0 && (
                  <th>{file.columns.optional}</th>
                )}
              {file.columns.sid !== undefined &&
                file.columns.sid.length > 0 && <th>{file.columns.sid}</th>}
              {file.columns.firstName !== undefined &&
                file.columns.firstName.length > 0 && (
                  <th>{file.columns.firstName}</th>
                )}
              {file.columns.lastName !== undefined &&
                file.columns.lastName.length > 0 && (
                  <th>{file.columns.lastName}</th>
                )}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {file.sheetData.map((sheetRow, idx) => {
              return (
                <tr key={"grade-row-" + idx}>
                  {file.columns.grade !== undefined &&
                    file.columns.grade.length > 0 && (
                      <td>{sheetRow[file.columns.grade]}</td>
                    )}
                  {file.columns.optional !== undefined &&
                    file.columns.optional.length > 0 && (
                      <td>{sheetRow[file.columns.optional]}</td>
                    )}
                  {file.columns.sid !== undefined &&
                    file.columns.sid.length > 0 && (
                      <td>{sheetRow[file.columns.sid]}</td>
                    )}
                  {file.columns.firstName !== undefined &&
                    file.columns.firstName.length > 0 && (
                      <td>{sheetRow[file.columns.firstName]}</td>
                    )}
                  {file.columns.lastName !== undefined &&
                    file.columns.lastName.length > 0 && (
                      <td>{sheetRow[file.columns.lastName]}</td>
                    )}
                  <td
                    className={
                      !sheetRow[FileReducer.GRADE_UPLOAD_VALID] &&
                      sheetRow[FileReducer.GRADE_UPLOAD_MESSAGE] !== ""
                        ? "bg-danger"
                        : ""
                    }
                  >
                    <div>{sheetRow[FileReducer.GRADE_UPLOAD_MESSAGE]}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const saveGrades = () => {
    let processedStudentIds = [];

    file.sheetData.map((sheetRow, idx) => {
      if (processedStudentIds.includes(sheetRow[file.columns.sid])) {
        dispatch({
          type: "gradeSaveResult",
          payload: {
            rowData: {
              index: idx,
              sheetRow: sheetRow,
              status:
                "Duplicate student ID detected [" +
                sheetRow[file.columns.sid] +
                "]"
            }
          }
        });
      } else {
        processedStudentIds.push(sheetRow[file.columns.sid]);
        UploadService.saveGrade({
          termCode: termcode,
          courseId: courseid,
          studentPidm: sheetRow[file.columns.sid],
          gradeCode: sheetRow[file.columns.grade],
          completedInd:
            file.columns.optional !== null
              ? sheetRow[file.columns.optional]
              : null
        })
          .then(res => {
            dispatch({
              type: "gradeSaveResult",
              payload: {
                rowData: {
                  index: idx,
                  sheetRow: sheetRow,
                  status: res.data.Message.Error
                }
              }
            });
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    });
    setLoadComplete(true);
  };

  const ColumnSelector = props => {
    return (
      <div className={"form-group"}>
        <label
          htmlFor={"select-column-" + props.name}
          className={props.required ? "label-required" : ""}
        >
          {props.label} column:
        </label>
        <select
          id={"select-column-" + props.name}
          className={"form-control"}
          value={file.columns == null ? "" : file.columns[props.name]}
          onChange={e => {
            dispatch({ type: props.reducerType, payload: e.target.value });
          }}
        >
          <option value="">{props.label}</option>
          {file.sheetCols.map(col => {
            return <option key={col.key}>{col.name}</option>;
          })}
        </select>
      </div>
    );
  };

  const StepNumber = props => {
    return (
      <div className={"container"}>
        <h2>
          {props.stepNum}. {props.stepDesc}
        </h2>
        {props.children}
      </div>
    );
  };

  return (
    <div>
      <PageHeader
        breadcrumbs={
          <BreadcrumbTitle pageTitle={"Grade Upload"}>
            <Link to="/courses">Course List</Link>
            <Link to={"/course/" + termcode + "/" + courseid}>
              <ShortCourseTitle title={file.CourseInfo.subjCrseList} />
            </Link>
          </BreadcrumbTitle>
        }
        pageTitle={"Grade Upload"}
      />

      <div className={"container"}>
        <div className={"row"}>
          <div className={"col"}>
            <strong>Term: {termcode}</strong>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col"}>
            <strong>{file.CourseInfo.crseTitle}</strong>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col"}>
            <strong>{file.CourseInfo.subjCrseList}</strong>
          </div>
        </div>
      </div>

      <StepNumber stepNum={"1"} stepDesc={"Select spreadsheet"}>
        <div className={"py-3"}>
          Upload a spreadsheet in Excel (.xlsx, .xls) or CSV (.csv) format. The
          first row of the spreadsheet will be interpreted as the names of each
          columns for use in the next step. An Excel template for this course is
          available to download from the{" "}
          <Link to={`/gradelist/${termcode}/${courseid}`}>Class Roster</Link>{" "}
          page.
        </div>
        <FileDropzone />
        {file.filename !== null && (
          <div>
            File selected: <strong> {file.filename}</strong>
          </div>
        )}
      </StepNumber>

      {file.sheetNames.length > 0 ? (
        <StepNumber stepNum={"2"} stepDesc={"Select sheet within spreadsheet"}>
          <div className={"py-3"}>
            Select the sheet within the spreadsheet file that contains your
            final grades.
          </div>
          <div className={"form-group"}>
            <label htmlFor={"sheetSelect"}>Select worksheet:</label>
            <select
              id={"sheetSelect"}
              className="form-control"
              value={file.sheetName}
              onChange={e =>
                dispatch({ type: "loadWorksheet", payload: e.target.value })
              }
            >
              <option value="">Select a worksheet</option>
              {file.sheetNames.map((sheet, idx) => {
                return <option key={"select-worksheet-" + idx}>{sheet}</option>;
              })}
            </select>
          </div>
        </StepNumber>
      ) : (
        <div />
      )}

      {file.sheetData && file.sheetData.length > 0 ? (
        <StepNumber stepNum={"3"} stepDesc={"Select columns within sheet"}>
          <div className={"py-3"}>
            <div className={"row"}>
              <div className={"col-md-6 col-sm-12"}>
                <div className={"card"}>
                  <div className={"card-body"}>
                    <p>
                      The following columns are <strong>required</strong>:
                    </p>
                    <dl>
                      <dt>Student ID</dt>
                      <dd>
                        Column which contains a student identifier, which can be
                        one of Student ID, Network ID, or YUPI.
                      </dd>
                      <dt>Final Grade</dt>
                      <dd>Column which contains the student's final grade.</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className={"col-md-6 col-sm-12"}>
                <div className={"card"}>
                  <div className={"card-body"}>
                    <p>
                      The following columns are <strong>optional</strong>:
                    </p>
                    <dl>
                      {file.SecurityInfo.optCreditStudents === "Y" && (
                        <>
                          <dt>Optional Distribution Credit</dt>
                          <dd>
                            Column which contains the affirmation for whether
                            the student should receive distributional writing
                            credit for this course.
                          </dd>
                        </>
                      )}
                      <dt>First Name</dt>
                      <dd>
                        Column which contains the student's first name. used
                        only for display but can be useful to determine any
                        errors that may occur during the uploading of the
                        grades.
                      </dd>
                      <dt>Last Name</dt>
                      <dd>
                        Column which contains the student's last name. used only
                        for display but can be useful to determine any errors
                        that may occur during the uploading of the grades.
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ColumnSelector
            name={"sid"}
            label={"Select Student ID"}
            required={true}
            reducerType={"setSid"}
          />
          <ColumnSelector
            name={"grade"}
            label={"Select Grade"}
            required={true}
            reducerType={"setGrade"}
          />
          {file.SecurityInfo.optCreditStudents === "Y" && (
            <ColumnSelector
              name={"optional"}
              label={"Select Optional Credit"}
              required={false}
              reducerType={"setOptional"}
            />
          )}
          <ColumnSelector
            name={"firstName"}
            label={"Select First Name"}
            required={false}
            reducerType={"setFirstName"}
          />
          <ColumnSelector
            name={"lastName"}
            label={"Select Last Name"}
            required={false}
            reducerType={"setLastName"}
          />
        </StepNumber>
      ) : (
        <div />
      )}

      {file.columns &&
      ((file.columns.sid && file.columns.sid.length > 0) ||
        (file.columns.grade && file.columns.grade.length > 0)) ? (
        <StepNumber stepNum={"4"} stepDesc={"Preview and Upload"}>
          <Row className={"d-flex"}>
            <Col md={6} className={"align-self-start"}>
              <div>
                {file.columns.sid &&
                  file.columns.sid.length > 0 &&
                  file.columns.grade &&
                  file.columns.grade.length > 0 &&
                  !loading && (
                    <div className={"py-4"}>
                      <Button
                        onClick={e => {
                          //  Disable button
                          setLoading(true);
                          saveGrades();
                        }}
                      >
                        Upload Grades
                      </Button>
                    </div>
                  )}

                {loadComplete && (
                  <div className={"py-4 align-self-start"}>
                    <Link to={"/course/" + termcode + "/" + courseid}>
                      <Button>Return to Grade Entry</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              {loadComplete && (
                <div>
                  <Row>
                    <Col>Total Students Processed:</Col>
                    <Col>{file.totals.studentsProcessed}</Col>
                  </Row>
                  <Row>
                    <Col>Grades Saved:</Col>
                    <Col>{file.totals.gradesSaved}</Col>
                  </Row>
                  <Row>
                    <Col>Grade Errors:</Col>
                    <Col>{file.totals.gradeErrors}</Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
          <PreviewGrades />
        </StepNumber>
      ) : (
        <div />
      )}
    </div>
  );
};

export default UploadGrades;
